import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledInput = styled.input`
  border: 1px solid ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.black};
  outline: 0;
  padding: 12px;
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  ${({ theme }) => theme.sizes.smallP};
`;

const StyledLabel = styled.label`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.serif};
  font-size: ${({ theme }) => theme.sizes.p};
  margin-bottom: 8px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 18px 0;
`;

function Input({ id, label, ...rest }) {
  return (
    <InputWrapper>
      <StyledLabel htmlFor={id}>{label}</StyledLabel>
      <StyledInput id={id} {...rest} />
    </InputWrapper>
  );
}

Input.propTypes = {
  id: PropTypes.string.isRequired,
};

Input.defaultProps = {};

export default Input;
