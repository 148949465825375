import axios from 'axios';

export const postToZapier = (url, data) => {
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  return axios.post(url, data, config);
};
