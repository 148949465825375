import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'components/Button';
import DotLoader from 'components/DotLoader';
import Input from 'components/Input';

import { postToZapier } from 'utils/helpers';

const Form = styled.form`
  margin: 0 auto;
  max-width: 700px;
`;

function ContactForm({ onSuccess }) {
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');

  function handleSubmit(e) {
    e.preventDefault();
    const data = { name, email, message };
    const zapierURL = 'https://hooks.zapier.com/hooks/catch/2403979/oziiwwp';
    setLoading(true);
    postToZapier(zapierURL, data)
      .then(res => {
        sendConversion();
      })
      .catch(err => console.log({ err }))
      .finally(() => {
        setLoading(false);
        onSuccess();
      });
  }

  function sendConversion() {
    if (window.ga) {
      window.ga(
        'send',
        'event',
        'conversion',
        'contactForm',
        'submission',
        '1'
      );
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        id="name"
        label="Name"
        value={name}
        onChange={e => setName(e.target.value)}
        required
      />
      <Input
        id="email"
        label="Email"
        type="email"
        value={email}
        onChange={e => setEmail(e.target.value)}
        required
      />
      <Input
        as="textarea"
        id="message"
        label="Message"
        value={message}
        onChange={e => setMessage(e.target.value)}
        rows="5"
        required
      />
      <Button type="submit">{loading ? <DotLoader /> : 'Submit'}</Button>
    </Form>
  );
}

ContactForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

ContactForm.defaultProps = {};

export default ContactForm;
