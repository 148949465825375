import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

const P = styled.p`
  font-family: ${({ theme }) => theme.sizes.p};
  text-align: center;
`;

const Wrapper = styled.div`
  min-height: 70vh;
`;

function SuccessMessage() {
  return (
    <Wrapper>
      <P>Thanks! We'll be in touch soon</P>
    </Wrapper>
  );
}

SuccessMessage.propTypes = {};

SuccessMessage.defaultProps = {};

export default SuccessMessage;
