import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Container from 'components/Container';
import ContactForm from 'pageComponents/contact/ContactForm';
import Layout from 'components/Layout';
import Nav from 'components/Nav';
import SEO from 'components/SEO';
import SuccessMessage from 'pageComponents/contact/SuccessMessage';
import { TextWrapper, Title } from './about';

function Contact({ data, location }) {
  const [success, setSuccess] = React.useState(false);
  const { pageTitle, pageDescription, seoImage } = data.contentfulPageSeo;
  const seo = {
    description: pageDescription,
    imageUrl: seoImage.file.url,
    title: pageTitle,
  };

  return (
    <Layout>
      <SEO {...seo} />
      <Nav location={location} navTheme="dark" />
      <Container>
        <TextWrapper>
          <Title>Contact</Title>
          {!success ? (
            <ContactForm onSuccess={() => setSuccess(true)} />
          ) : (
            <SuccessMessage />
          )}
        </TextWrapper>
      </Container>
    </Layout>
  );
}

Contact.propTypes = {
  location: PropTypes.any.isRequired,
};

Contact.defaultProps = {};

export const query = graphql`
  query ContactPageQuery {
    contentfulPageSeo(identifier: { eq: "contact" }) {
      pageTitle
      pageDescription
      seoImage {
        file {
          url
        }
      }
    }
  }
`;

export default Contact;
