import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const blink = keyframes`
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
`;

const Dot = styled.div`
  animation-name: ${blink};
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  background-color: ${({ bgColor, theme }) => theme.colors[bgColor]};
  border-radius: 100%;
  margin: 0 8px;
  height: 8px;
  width: 8px;
  :nth-child(2) {
    animation-delay: 0.2s;
  }
  :nth-child(3) {
    animation-delay: 0.4s;
  }
`;

const StyledDotLoader = styled.div`
  display: inline-flex;
`;

function DotLoader({ bgColor }) {
  return (
    <StyledDotLoader>
      {Array(3)
        .fill('')
        .map((el, i) => (
          <Dot bgColor={bgColor} key={i} />
        ))}
    </StyledDotLoader>
  );
}

DotLoader.propTypes = {
  bgColor: PropTypes.string,
};

DotLoader.defaultProps = {
  bgColor: 'white',
};

export default DotLoader;
