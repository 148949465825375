import styled from 'styled-components';

const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.black};
  border: none;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.serif};
  font-size: ${({ theme }) => theme.sizes.p};
  outline: 0;
  padding: 16px 34px;
  transition: opacity 200ms ease-out;
  :hover {
    opacity: 0.5;
  }
`;

export default Button;
